import React, { FC, ReactNode, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

// * sidebar nav component here
import Sidebar from "@Ship/Components/Sidebar";
import Header from "@Ship/Components/Header";
import CollapseSidebar from "@Ship/Components/Sidebar/CollapseSidebar";
import SuccessModal from "@Ship/Components/SuccessModal/SuccessModal";
import Loader from "@Ship/Components/Loader";
import { useSocket } from "@Ship/Hooks/socket/socket";

// * Layout component instance
const BaseLayout: FC<{ children: ReactNode }> = (props: {
  children: ReactNode;
}) => {
  // * content children component
  const { children } = props;
  const [collapseSidebar, setCollapseSidebar] = useState(true);

  // * Collapse sidebar automatically if on mobile
  useEffect(() => {
    if (isMobile) setCollapseSidebar(true);
  }, []);

  // * Socket initialization
  useEffect(() => {
    const socketInit = async () => {
      // * initialize socket & notifications
      await useSocket.initialize();
    };
    socketInit();

    return () => {
      useSocket.unsubscribe();
    };
  }, []);

  return (
    <>
      <SuccessModal />
      <Loader />
      {/* container layout */}
      <div className="flex flex-col relative overflow-hidden h-[100dvh] min-h-[100dvh]">
        {/* header */}
        <Header
          collapseSidebar={collapseSidebar}
          setCollapseSidebar={setCollapseSidebar}
        />
        <div className="flex flex-1 overflow-y-auto h-full ">
          {/* Conditionally render the sidebar */}
          <aside
            className={`ease-in-out duration-300 bg-5b5-softer-blue h-screen z-20 justify-items-center fixed top-[80px] mobile:top-[59px] ${
              collapseSidebar
                ? "w-[80px] mobile:w-0"
                : "w-[400px] mobile:w-[250px]"
            }`}
            id="aside"
          >
            {collapseSidebar ? (
              <CollapseSidebar
                collapseSidebar={collapseSidebar}
                setCollapseSidebar={setCollapseSidebar}
              />
            ) : (
              <Sidebar
                collapseSidebar={collapseSidebar}
                setCollapseSidebar={setCollapseSidebar}
              />
            )}
          </aside>
          <div className={`flex-grow max-h-full h-full overflow-y-auto`}>
            {/* Adjust padding if sidebar is hidden */}
            <main
              className={`relative flex h-full max-h-full overflow-y-auto pt-[80px] mobile:pt-[59px] max-w-full w-full`}
            >
              {/* Adjust width based on whether the sidebar is shown */}
              <div
                className={`${
                  collapseSidebar
                    ? "w-[80px] min-w-[80px]"
                    : "w-[400px] min-w-[400px]"
                }  mobile:w-0 mobile:min-w-0`}
              />
              <div className="z-9 h-full w-full ">{children}</div>
            </main>
          </div>
        </div>
        {/* footer */}
        <div></div>
      </div>
    </>
  );
};

// *
export default BaseLayout;
