export interface TokensType {
  accessToken: string;
  refreshToken?: string;
}

const TOKEN_KEY = "refresh_token";
const ACCESS_TOKEN_KEY = "access_token";
const hasWindow = typeof window !== "undefined";

/**
 * Function to store the access token to local storage
 * @param tokens - contains the accessToken and refreshToken
 */
export const storeToken = (accessToken: string, refreshToken: string): void => {
  if (hasWindow) {
    window?.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    window?.localStorage.setItem(TOKEN_KEY, refreshToken);
    // if (localStorage) {
    //   window?.localStorage.setItem(TOKEN_KEY, token);
    // } else {
    //   window?.sessionStorage.setItem(TOKEN_KEY, token);
    // }
  }
};

export const storeAccessToken = (
  token: string,
  localStorage: boolean
): void => {
  if (hasWindow) {
    if (localStorage) {
      window?.localStorage.setItem(ACCESS_TOKEN_KEY, token);
    } else {
      window?.sessionStorage.setItem(ACCESS_TOKEN_KEY, token);
    }
  }
};
/**
 * Function to retrieve access token stored in local storage
 */
export const getAccessToken = (tokenKey: string): string | null => {
  let accessToken: null | string = "";
  if (hasWindow) {
    accessToken = window.localStorage.getItem(tokenKey);
  }
  return accessToken;
};

export const clearStorageTokens = (tokenKeys: string[]): void => {
  if (hasWindow) {
    tokenKeys.forEach((tokenKey) => window.localStorage.removeItem(tokenKey));
  }
};

export const clearTokenCookies = (tokenKeys: string[]): void => {
  if (hasWindow) {
    tokenKeys.forEach((tokenKey) => {
      document.cookie = `${tokenKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  }
};
