import React from "react";
// import { HiMenu } from "react-icons/hi";
// import { MdShoppingCart } from "react-icons/md";// import { FaMoneyCheckAlt } from "react-icons/fa";
import { IoReceipt, IoRestaurant, IoPerson } from "react-icons/io5";
import { HiChatBubbleLeft } from "react-icons/hi2";

const PatronRoutes = [
  {
    name: "Restaurants & Stores",
    icon: <IoRestaurant size={20} />,
    route: "/landing",
  },
  {
    name: "Orders",
    icon: <IoReceipt size={20} />,
    route: "/payment-history",
  },
  {
    name: "Profile",
    icon: <IoPerson size={20} />,
    route: "/profile",
  },
  {
    name: "Messages",
    icon: <HiChatBubbleLeft size={20} />,
    route: "/conversations",
  },
];

export default PatronRoutes;
