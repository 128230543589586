/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import PatronRoutes from "./patronRouters";
import SidebarProfile from "./SidebarProfile";
import { clearStorageTokens, clearTokenCookies } from "@Ship/Helpers/tokens";
import { deleteCookie, setCookie } from "cookies-next";
import { allOrdersSubscriptionStore } from "@Ship/store/allOrdersSubscriptionStore";
import { getAuth, signOut } from "firebase/auth";
import { useLoaderStore } from "@Ship/store/loaderStore";
import { useConversationStore } from "@Ship/store/conversationsStore";

// * Component instance
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Sidebar = (_props: any) => {
  // * route list here
  const auth = getAuth();
  const { pathname } = useRouter();
  const router = useRouter();
  const [showSidebar, setShowSidebar] = useState(true);
  const [signOutWarning, setSignOutWarning] = useState(false);
  const setIsLoading = useLoaderStore((state: any) => state.setIsLoading);

  const purchaseHistoryList = allOrdersSubscriptionStore(
    (state: any) => state.purchaseHistoryList
  );

  const unreadConversationsCount = useConversationStore(
    (state: any) => state.unreadConversationsCount
  );

  useEffect(() => {
    setTimeout(() => {
      setShowSidebar(_props.collapseSidebar);
    }, 350);
  }, [_props.collapseSidebar]);

  const confirmSignOut = async () => {
    setIsLoading(true);
    const tokenKeysArray = ["accessToken", "refreshToken"];
    clearStorageTokens(tokenKeysArray);
    clearTokenCookies(tokenKeysArray);
    deleteCookie("user_id");
    setCookie("user_perm_location", "");
    await signOut(auth);
    setTimeout(() => {
      setIsLoading(false);
      router.reload();
    }, 5000);
  };

  return (
    <>
      {!showSidebar ? (
        <nav className="relative mt-16 mobile:mt-0 mobile:fixed">
          <div className="absolute left-[210px] top-[20px] z-10 drop-shadow-xl mobile:block hidden">
            <button
              type="button"
              className=""
              onClick={() => _props.setCollapseSidebar(!_props.collapseSidebar)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.0156 11.7344C12.3379 12.1445 12.3086 12.7305 11.8984 13.0527C11.7227 13.1992 11.5176 13.2578 11.3125 13.2578C11.0195 13.2578 10.7559 13.1699 10.5801 12.9355L6.625 8.16016L2.64062 12.9062C2.46484 13.1406 2.20117 13.2578 1.9375 13.2578C1.70312 13.2578 1.49805 13.1699 1.32227 13.0234C0.912109 12.7012 0.882812 12.1152 1.20508 11.7051L5.39453 6.69531L1.20508 1.68555C0.882812 1.27539 0.912109 0.689453 1.32227 0.367188C1.73242 0.0449219 2.31836 0.0742188 2.64062 0.484375L6.625 5.23047L10.5801 0.484375C10.9023 0.0742188 11.4883 0.0449219 11.8984 0.367188C12.3086 0.689453 12.3379 1.27539 12.0156 1.68555L7.82617 6.69531L12.0156 11.7344Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>

          <div className="absolute left-[210px] top-[20px] z-10 drop-shadow-xl mobile:block hidden">
            <button
              type="button"
              className=""
              onClick={() => _props.setCollapseSidebar(!_props.collapseSidebar)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.0156 11.7344C12.3379 12.1445 12.3086 12.7305 11.8984 13.0527C11.7227 13.1992 11.5176 13.2578 11.3125 13.2578C11.0195 13.2578 10.7559 13.1699 10.5801 12.9355L6.625 8.16016L2.64062 12.9062C2.46484 13.1406 2.20117 13.2578 1.9375 13.2578C1.70312 13.2578 1.49805 13.1699 1.32227 13.0234C0.912109 12.7012 0.882812 12.1152 1.20508 11.7051L5.39453 6.69531L1.20508 1.68555C0.882812 1.27539 0.912109 0.689453 1.32227 0.367188C1.73242 0.0449219 2.31836 0.0742188 2.64062 0.484375L6.625 5.23047L10.5801 0.484375C10.9023 0.0742188 11.4883 0.0449219 11.8984 0.367188C12.3086 0.689453 12.3379 1.27539 12.0156 1.68555L7.82617 6.69531L12.0156 11.7344Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>

          <div className="absolute -right-5 -top-10 z-10 drop-shadow-xl mobile:hidden block">
            <button
              type="button"
              className="relative left-2"
              onClick={() => _props.setCollapseSidebar(!_props.collapseSidebar)}
            >
              <svg
                width="82"
                height="82"
                viewBox="0 0 82 82"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={_props.collapseSidebar ? "rotate-180" : ""}
              >
                <g filter="url(#filter0_d_1160_19917)">
                  <circle cx="50" cy="41" r="26" fill="white" />
                </g>
                <path
                  d="M53.25 52C52.7227 52 52.2539 51.8242 51.9023 51.4727L42.5273 42.0977C41.7656 41.3945 41.7656 40.1641 42.5273 39.4609L51.9023 30.0859C52.6055 29.3242 53.8359 29.3242 54.5391 30.0859C55.3008 30.7891 55.3008 32.0195 54.5391 32.7227L46.5117 40.75L54.5391 48.8359C55.3008 49.5391 55.3008 50.7695 54.5391 51.4727C54.1875 51.8242 53.7188 52 53.25 52Z"
                  className="fill-5b5-softer-blue"
                />
                <defs>
                  <filter
                    id="filter0_d_1160_19917"
                    x="0"
                    y="0"
                    width="82"
                    height="82"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="-9" />
                    <feGaussianBlur
                      result="blurOut"
                      in="offOut"
                      stdDeviation="10"
                    />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1160_19917"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_1160_19917"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </button>
          </div>
          <ul>
            <SidebarProfile />
            {PatronRoutes.map(({ name, route, icon }) => (
              <li key={name}>
                <button
                  type="button"
                  onClick={() => {
                    router.push(route);
                    _props.setCollapseSidebar(!_props.collapseSidebar);
                  }}
                >
                  <div className="flex pl-9 pb-[20px] cursor-pointer">
                    <div
                      className={
                        pathname.includes(route)
                          ? "flex relative flex-col pr-[17px] justify-center text-white"
                          : "flex relative flex-col pr-[17px] justify-center text-gray-300"
                      }
                    >
                      {route.includes("payment-history") &&
                        purchaseHistoryList &&
                        purchaseHistoryList.filter(
                          (order: any) => order.orderStatus !== "claimed"
                        ).length > 0 && (
                          <div className="absolute top-0 right-2 transform -translate-y-[50%] bg-jolt-red text-white font-bold rounded-full h-[17px] w-[17px] flex justify-center items-center text-xs text-center">
                            {
                              purchaseHistoryList.filter(
                                (order: any) => order.orderStatus !== "claimed"
                              ).length
                            }
                          </div>
                        )}
                      {route.includes("conversations") &&
                        unreadConversationsCount > 0 && (
                          <div className="absolute top-0 right-2 transform -translate-y-[50%] bg-jolt-red text-white font-bold rounded-full h-[17px] w-[17px] flex justify-center items-center text-xs text-center">
                            {unreadConversationsCount}
                          </div>
                        )}
                      {icon}
                    </div>
                    <div
                      className={
                        pathname.includes(route)
                          ? "text-[22px] text-jolt-white font-semibold mobile:text-[14px]"
                          : "text-[22px] text-gray-300 font-semibold mobile:text-[14px]"
                      }
                    >
                      {name}
                    </div>
                  </div>
                </button>
              </li>
            ))}
          </ul>
          <div className="py-2 ml-[35px] mr-[99px] mobile:block">
            <svg
              className="w-[200px]"
              width="218"
              height="2"
              viewBox="0 0 218 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                y1="1.21094"
                x2="218"
                y2="1.21094"
                stroke="#C6CED4"
                strokeDasharray="3 3"
              />
            </svg>
          </div>

          <ul className="mobile:block">
            <li>
              {auth.currentUser?.isAnonymous ? (
                <button
                  onClick={() => {
                    router.push("/login");
                  }}
                >
                  <div className="flex ml-10 mt-5 mb-[26px] px-6 py-2 rounded-md border border-white cursor-pointer">
                    <div className="text-[22px] text-[#FFFFFF] font-semibold mobile:text-[14px] ">
                      {"Log In"}
                    </div>
                  </div>
                </button>
              ) : (
                <button
                  onClick={async () => {
                    setSignOutWarning(true);
                  }}
                >
                  <div className="flex pl-10 pt-5 pb-[26px] cursor-pointer">
                    <div className="flex flex-col pr-[17px] justify-center text-[#C6CED4]">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 9 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 9C0.725 9 0.4895 8.90217 0.2935 8.7065C0.0978333 8.5105 0 8.275 0 8V1C0 0.725 0.0978333 0.4895 0.2935 0.2935C0.4895 0.0978333 0.725 0 1 0H4.5V1H1V8H4.5V9H1ZM6.5 7L5.8125 6.275L7.0875 5H3V4H7.0875L5.8125 2.725L6.5 2L9 4.5L6.5 7Z"
                          fill="#C6CED5"
                        />
                      </svg>
                    </div>
                    <div className="text-[22px] text-[#C6CED4] font-semibold mobile:text-[14px] ">
                      {"Sign Out"}
                    </div>
                  </div>
                </button>
              )}
            </li>
          </ul>
        </nav>
      ) : null}

      {signOutWarning && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-100">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-3 w-[90%]">
            <div className="text-center text-gray-900 font-bold text-lg">
              Are you sure you want to sign out?
            </div>
            <div className="text-center text-gray-900 ">
              You will have to log in again to access your account.
            </div>
            <div className="grid grid-cols-2 gap-2 mt-5">
              <button
                className="bg-red-500 text-white rounded-lg py-4 mx-auto w-full"
                onClick={() => setSignOutWarning(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white rounded-lg py-4 text-center mx-auto w-full"
                onClick={() => confirmSignOut()}
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// *
export default Sidebar;
