import { create } from "zustand";
import { persist } from "zustand/middleware";

type Product = {
  productId: number;
  quantity: number;
  price: string;
  name: string;
  qrCode: string;
  images: Array<any>;
  businessId: string;
  selectedOptions?: any[];
  extraCost?: number;
  basePrice: number;
};

type Cart = {
  products: Product[];
  specialInstructions?: string;
};

type CartsState = {
  carts: Record<string, Cart>;
  removeAll: (businessId: string) => void;
  setProducts: (businessId: string, newProducts: Product[]) => void;
  addSingleProduct: (businessId: string, item: Product) => void;
  addProduct: (businessId: string, item: Product) => void;
  deleteProduct: (businessId: string, item: Product) => void;
  setQty: (businessId: string, item: Product) => void;
  removeProduct: (businessId: string, item: Product) => void;
  setSpecialInstructions: (businessId: string, instructions: string) => void;
};

export const useProductCartStore = create(persist<CartsState>((set) => ({
  carts: {},

  removeAll: (businessId: string) => {
    console.log("REMOVE ALL TRIGEGRED");
    set((state) => {
      const updatedCarts = { ...state.carts };
      delete updatedCarts[businessId];
      return { carts: updatedCarts };
    });
  },

  setProducts: (businessId: string, newProducts: Product[]) => {
    console.log("SET PRODUCTS TRIGEGRED");
    set((state) => ({
      carts: {
        ...state.carts,
        [businessId]: { products: newProducts },
      },
    }));
  },

  addSingleProduct: (businessId: string, item: Product) => {
    console.log("ADD SINGLE PRODUCT TRIGEGRED");
    set((state) => {
      const currentCart = state.carts[businessId]?.products || [];
      const inCart = currentCart.some(
        (product) => product.productId === item.productId
      );

      const updatedProducts = inCart
        ? currentCart.map((product) =>
            product.productId === item.productId
              ? { ...product, quantity: item.quantity }
              : product
          )
        : [...currentCart, item];

      return {
        carts: {
          ...state.carts,
          [businessId]: { products: updatedProducts },
        },
      };
    });
  },

  addProduct: (businessId: string, item: Product) => {
    console.log("ADD PRODUCT TRIGEGRED");
    set((state) => {
      const currentCart = state.carts[businessId]?.products || [];
      const inCart = currentCart.some(
        (product) =>
          product.productId === item.productId &&
          JSON.stringify(product.selectedOptions || []) ===
            JSON.stringify(item.selectedOptions || [])
      );

      const updatedProducts = inCart
        ? currentCart.map((product) =>
            product.productId === item.productId &&
            JSON.stringify(product.selectedOptions || []) ===
              JSON.stringify(item.selectedOptions || [])
              ? { ...product, quantity: product.quantity + item.quantity }
              : product
          )
        : [...currentCart, item];

      return {
        carts: {
          ...state.carts,
          [businessId]: { products: updatedProducts },
        },
      };
    });
  },

  deleteProduct: (businessId: string, item: Product) => {
    console.log("DELETE PRODUCT TRIGEGRED");
    set((state) => {
      const currentCart = state.carts[businessId]?.products || [];
      const filteredProduct = currentCart.filter(
        (p) =>
          p.productId === item.productId &&
          JSON.stringify(p.selectedOptions || []) ===
            JSON.stringify(item.selectedOptions || [])
      );

      let updatedProducts;
      if ((filteredProduct[0]?.quantity ?? 0) <= 1) {
        updatedProducts = currentCart.filter((product) => {
          if (item.selectedOptions) {
            return (
              JSON.stringify(product.selectedOptions || []) !==
              JSON.stringify(item.selectedOptions || [])
            );
          }

          return product.productId !== item.productId;
        });
      } else {
        updatedProducts = currentCart.map((product) =>
          product.productId === item.productId &&
          JSON.stringify(product.selectedOptions || []) ===
            JSON.stringify(item.selectedOptions || [])
            ? { ...product, quantity: product.quantity - 1 }
            : product
        );
      }


      if (updatedProducts.length === 0) {
        const updatedCarts = { ...state.carts };
        delete updatedCarts[businessId];
        return { carts: updatedCarts };
      }

      return {
        carts: {
          ...state.carts,
          [businessId]: { products: updatedProducts },
        },
      };
    });
  },

  setQty: (businessId: string, item: {
      productId: number,
      quantity: number,
      price: string,
      name: string,
      qrCode: string,
      images: Array<any>,
      businessId: string,
      selectedOptions?: any[],
      extraCost?: number,
      basePrice?: number,
  }) => {
    console.log("SET QTY TRIGEGRED");
    set((state) => {
      const currentCart = state.carts[businessId]?.products || [];
      const updatedProducts = currentCart.map((product) =>
        product.productId === item.productId
          ? { ...product, quantity: item.quantity }
          : product
      );

      return {
        carts: {
          ...state.carts,
          [businessId]: { products: updatedProducts },
        },
      };
    });
  },

  removeProduct: (businessId: string, item: {
      productId: number,
      quantity?: number,
      price: string,
      name: string,
      qrCode: string,
      images: Array<any>,
      businessId: string,
      selectedOptions?: any[],
      extraCost?: number,
      basePrice?: number,
  }) => {
    console.log("REMOVE PRODUCT TRIGEGRED");
    set((state) => {
      const currentCart = state.carts[businessId]?.products || [];
      const updatedProducts = currentCart.filter(
        (product) => product.productId !== item.productId
      );

      if (updatedProducts.length === 0) {
        const updatedCarts = { ...state.carts };
        delete updatedCarts[businessId];
        return { carts: updatedCarts };
      }

      return {
        carts: {
          ...state.carts,
          [businessId]: { products: updatedProducts },
        },
      };
    });
  },
    setSpecialInstructions: (businessId: string, instructions: string) => {
    set((state) => ({
        carts: {
          ...state.carts,
          [businessId]: {
            ...state.carts[businessId],
            specialInstructions: instructions,
            products: state.carts[businessId]?.products || [],
          },
        },
      }));
    },
}), 
{
  name: "product-cart-storage",
  getStorage: () => localStorage,
}));


export const useBusinessCartStore = (businessId: string) => {
  return {
    products: useProductCartStore((state) => state.carts[businessId]?.products || []),
    specialInstructions: useProductCartStore((state) => state.carts[businessId]?.specialInstructions || ""),
    removeAll: () => useProductCartStore.getState().removeAll(businessId),
    addProduct: (product: Product) => useProductCartStore.getState().addProduct(businessId, product),
    addSingleProduct: (product: Product) => useProductCartStore.getState().addSingleProduct(businessId, product),
    setProducts: (newProducts: any) => useProductCartStore.getState().setProducts(businessId, newProducts),
    setQty: (product: Product) => useProductCartStore.getState().setQty(businessId, product),
    deleteProduct: (product: Product) => useProductCartStore.getState().deleteProduct(businessId, product),
    removeProduct: (product: Product) => useProductCartStore.getState().removeProduct(businessId, product),
    setSpecialInstructions: (instructions: string) => 
      useProductCartStore.getState().setSpecialInstructions(businessId, instructions),
  };
};