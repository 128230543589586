import io from "socket.io-client";

const socketUrl = process.env.API_URL || "";
const socket = io(socketUrl);

const useSocket = {
  socket,

  initialize: async () => {
    /**
     * * declare default socket events
     */
    socket.on("connect", () => {
      console.log("connect");
    });

    socket.on("disconnect", () => {
      console.log("disconnect");
    });

    socket.on("reconnecting", () => {
      console.log("reconnecting");
    });

    socket.on("reconnect_error", () => {
      console.log("reconnect_error");
    });

    socket.on("reconnect_failed", (_socket) => {
      console.log("reconnect_failed", { _socket });
    });
  },

  unsubscribe: async () => {
    socket.off("connect", () => {});
    socket.off("disconnect", () => {});
    socket.off("reconnecting", () => {});
    socket.off("reconnect_error", () => {});
    socket.off("reconnect_failed", () => {});
  },
};

export { useSocket };
