/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import PatronRoutes from "./patronRouters";
import { allOrdersSubscriptionStore } from "@Ship/store/allOrdersSubscriptionStore";
import { useConversationStore } from "@Ship/store/conversationsStore";

// * Component instance
const CollapseSidebar = (_props: any) => {
  // * route list here
  const { pathname } = useRouter();
  const router = useRouter();
  const [showSidebar, setShowSidebar] = useState(false);

  const unreadConversationsCount = useConversationStore(
    (state: any) => state.unreadConversationsCount
  );

  const purchaseHistoryList = allOrdersSubscriptionStore(
    (state: any) => state.purchaseHistoryList
  );
  useEffect(() => {
    setTimeout(() => {
      setShowSidebar(_props.collapseSidebar);
    }, 350);
  }, [_props.collapseSidebar]);

  // * return react component
  return (
    <>
      {/* <aside className="bg-joltpay-blue-bg w-[80px] flex justify-center transition-transform mobile:hidden"> */}
      {showSidebar ? (
        <nav className="fixed flex w-[80px] justify-center items-center mobile:hidden">
          <ul className="p-0">
            <li className="flex justify-center pb-[36px] pt-5" key="collapse">
              <div className="cursor-pointer rotate-180">
                <button
                  type="button"
                  onClick={() => {
                    _props.setCollapseSidebar(!_props.collapseSidebar);
                  }}
                >
                  <svg
                    width="15"
                    height="23"
                    viewBox="0 0 15 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.25 23C11.7227 23 11.2539 22.8242 10.9023 22.4727L1.52734 13.0977C0.765625 12.3945 0.765625 11.1641 1.52734 10.4609L10.9023 1.08594C11.6055 0.324219 12.8359 0.324219 13.5391 1.08594C14.3008 1.78906 14.3008 3.01953 13.5391 3.72266L5.51172 11.75L13.5391 19.8359C14.3008 20.5391 14.3008 21.7695 13.5391 22.4727C13.1875 22.8242 12.7188 23 12.25 23Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </li>
            {PatronRoutes.map(({ name, route, icon }) => (
              <li key={name} className="flex justify-center">
                <button
                  type="button"
                  onClick={() => {
                    router.push(route);
                  }}
                >
                  <div className="flex pb-[26px] cursor-pointer">
                    <div
                      className={
                        pathname.includes(route)
                          ? "flex relative flex-col justify-center text-jolt-white"
                          : "flex relative flex-col justify-center text-gray-300"
                      }
                    >
                      {route.includes("payment-history") &&
                        purchaseHistoryList.filter(
                          (order: any) => order.orderStatus !== "claimed"
                        ).length > 0 && (
                          <div className="absolute top-0 right-2 transform -translate-y-[50%] bg-jolt-red text-white font-bold rounded-full h-[17px] w-[17px] flex justify-center items-center text-xs text-center">
                            {
                              purchaseHistoryList.filter(
                                (order: any) => order.orderStatus !== "claimed"
                              ).length
                            }
                          </div>
                        )}
                      {route.includes("conversations") &&
                        unreadConversationsCount > 0 && (
                          <div className="absolute top-0 right-2 transform -translate-y-[50%] bg-jolt-red text-white font-bold rounded-full h-[17px] w-[17px] flex justify-center items-center text-xs text-center">
                            {unreadConversationsCount}
                          </div>
                        )}
                      {icon}
                    </div>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </nav>
      ) : null}
      {/* </aside> */}
    </>
  );
};

export default CollapseSidebar;
