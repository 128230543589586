import React, { useRef, useEffect, CSSProperties, useState } from "react";
import { IoCartOutline, IoReceiptOutline } from "react-icons/io5";
import Link from "next/link";
import { useProductCartStore } from "@Ship/store/useProductCartStore";
import { allOrdersSubscriptionStore } from "@Ship/store/allOrdersSubscriptionStore";
import { useLogoAnimationStore } from "@Ship/store/logoAnimationStore";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Header = (_props: any) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const hasAnimationPlayed = useLogoAnimationStore(
    (state) => state.hasPlayedAnimation
  );
  const setPlayAnimation = useLogoAnimationStore(
    (state) => state.markAnimationPlayed
  );

  // Add a state to prevent rendering change during component mount
  const [initialRender, setInitialRender] = useState(true);
  const [shouldShowVideo, setShouldShowVideo] = useState(false);

  useEffect(() => {
    // Determine whether to show video or image on initial render
    if (initialRender) {
      if (!hasAnimationPlayed) {
        setShouldShowVideo(true);
      }
      setInitialRender(false);
    }
  }, [hasAnimationPlayed, initialRender]);

  useEffect(() => {
    if (videoRef.current && shouldShowVideo) {
      // Add event listener for when the video ends
      const handleVideoEnd = () => {
        if (videoRef.current) {
          videoRef.current.pause();
        }
      };

      videoRef.current.addEventListener("ended", handleVideoEnd);

      // Start playing the video
      videoRef.current
        .play()
        .then(() => {
          console.log("Video played successfully playAnimation");
          setPlayAnimation();
        })
        .catch((error) => {
          console.error("Error playing the video:", error);
        });

      // Clean up the event listener when component unmounts
      return () => {
        setShouldShowVideo(false);
        if (videoRef.current) {
          videoRef.current.removeEventListener("ended", handleVideoEnd);
        }
      };
    }
  }, [shouldShowVideo, setPlayAnimation]);

  const purchaseHistoryList = allOrdersSubscriptionStore(
    (state: any) => state.purchaseHistoryList
  );

  const carts = useProductCartStore((state) => state.carts);
  // numberOfCarts is the total number of items in all carts
  const numberOfCarts = Object.values(carts).reduce((total, cart) => {
    return (
      total +
      cart.products.reduce((cartTotal, product) => {
        return cartTotal + (product.quantity || 0);
      }, 0)
    );
  }, 0);

  const videoStyle: CSSProperties = {
    WebkitMaskImage: "-webkit-radial-gradient(white, black)",
    WebkitBackfaceVisibility: "hidden",
    backfaceVisibility: "hidden",
    isolation: "isolate",
  } as CSSProperties;

  return (
    <>
      <header className="fixed bg-white w-full h-[80px] max-h-[80px] min-h-[80px] flex justify-between mobile:h-[59px] mobile:max-h-[59px] mobile:min-h-[59px] shadow-md z-20 top-0">
        <div className="flex bg-[#FFFFFF] font-semibold h-[80px] mobile:h-[59px] mobile:w-full">
          {/* Sidebar toggle button (hidden when anonymous) */}
          <div className="flex flex-col justify-center min-h-full font-semibold text-3xl text-[#1282A2] pl-[30px] mobile:pl-[18px]">
            <button
              type="button"
              className="relative flex"
              onClick={() => _props.setCollapseSidebar(!_props.collapseSidebar)}
            >
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 4.25C0 3.58594 0.546875 3 1.25 3H16.25C16.9141 3 17.5 3.58594 17.5 4.25C17.5 4.95312 16.9141 5.5 16.25 5.5H1.25C0.546875 5.5 0 4.95312 0 4.25ZM0 10.5C0 9.83594 0.546875 9.25 1.25 9.25H16.25C16.9141 9.25 17.5 9.83594 17.5 10.5C17.5 11.2031 16.9141 11.75 16.25 11.75H1.25C0.546875 11.75 0 11.2031 0 10.5ZM16.25 18H1.25C0.546875 18 0 17.4531 0 16.75C0 16.0859 0.546875 15.5 1.25 15.5H16.25C16.9141 15.5 17.5 16.0859 17.5 16.75C17.5 17.4531 16.9141 18 16.25 18Z"
                  fill="#000000"
                />
              </svg>
            </button>
          </div>

          <Link
            href={"/landing"}
            className="flex flex-row justify-center place-items-center min-h-full max-h-full"
          >
            {shouldShowVideo ? (
              <video
                ref={videoRef}
                className="h-[80px] mobile:h-[60px] object-contain"
                style={videoStyle}
                muted
                playsInline
                autoPlay
              >
                <source
                  src="/new_logos/ywait-animation-comp-short.mp4"
                  type="video/mp4"
                />
                {/* Fallback to static image if video doesn't load */}
                <img
                  src="/new_logos/newYWaitLogoText.png"
                  className="h-[38px] mobile:h-[29px] object-contain ml-[21px]"
                  alt="Y Wait Logo"
                />
              </video>
            ) : (
              <img
                src="/new_logos/newYWaitLogoText.png"
                className="h-[38px] mobile:h-[29px] object-contain ml-[21px]"
                alt="Y Wait Logo"
              />
            )}
          </Link>
        </div>
        <div className="flex pr-2">
          {/* Cart and orders buttons (hidden when anonymous) */}
          <>
            <Link
              href={"/payment-history"}
              className="flex justify-center items-center px-4 mobile:px-3"
            >
              <div className="relative flex justify-center bg-[#FFFFFF]">
                <IoReceiptOutline className=" h-[100%] cursor-pointer w-[27px] mobile:w-[22px]" />
                {purchaseHistoryList.filter(
                  (order: any) => order.orderStatus !== "claimed"
                ).length > 0 && (
                  <div className="absolute flex top-0 right-0 translate-x-[50%] transform -translate-y-[50%] bg-jolt-red text-white font-bold rounded-full h-[17px] w-[17px] justify-center items-center text-xs text-center">
                    {
                      purchaseHistoryList.filter(
                        (order: any) => order.orderStatus !== "claimed"
                      ).length
                    }
                  </div>
                )}
              </div>
            </Link>
          </>
          <Link
            href={"/allCarts"}
            className="flex justify-center items-center px-4 mobile:px-3"
          >
            <div className="relative flex justify-center bg-[#FFFFFF] ">
              <IoCartOutline className="z-20 h-[100%] cursor-pointer w-[30px] mobile:w-[25px]" />
              {numberOfCarts > 0 ? (
                <div className="absolute top-0 right-0 transform translate-x-[50%] -translate-y-[50%] bg-jolt-red text-white font-bold rounded-full h-[17px] w-[17px] flex justify-center items-center text-xs text-center">
                  {numberOfCarts}
                </div>
              ) : null}
            </div>
          </Link>
        </div>
      </header>
    </>
  );
};

export default Header;
