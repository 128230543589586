/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useCallback } from "react";
import { useSuccessModalStore } from "../../store/successModalStore";
import cn from "classnames";
import { useRouter } from "next/router";

const SuccessModal = () => {
  const router = useRouter();
  const modalData = useSuccessModalStore((state: any) => state.modalData);
  const setModalData = useSuccessModalStore((state: any) => state.setModalData);

  const modalClass = cn({
    "modal modal-middle sm:modal-middle": true,
    "modal-open": modalData?.open,
  });

  const updateStatus = useCallback(() => {
    setTimeout(() => setModalData({ open: false, message: "" }), 2000);
  }, [setModalData, router]);

  useEffect(() => {
    updateStatus();
  }, [updateStatus]);

  return (
    <>
      <div className={modalClass}>
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="bg-[#010101B2] p-5 rounded-[10px] w-[400px] h-[180px] mobile:w-[300px] mobile:h-[150px]">
              <div className="grid grid-cols-3 place-items-center gap-6">
                <div className="col-span-3">
                  <div className="tablet:show mobile:hidden">
                    <svg
                      width="60"
                      height="61"
                      viewBox="0 0 60 61"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 30.5C0 13.9766 13.3594 0.5 30 0.5C46.5234 0.5 60 13.9766 60 30.5C60 47.1406 46.5234 60.5 30 60.5C13.3594 60.5 0 47.1406 0 30.5ZM43.4766 25.3438C44.7656 24.0547 44.7656 22.0625 43.4766 20.7734C42.1875 19.4844 40.1953 19.4844 38.9062 20.7734L26.25 33.4297L20.9766 28.2734C19.6875 26.9844 17.6953 26.9844 16.4062 28.2734C15.1172 29.5625 15.1172 31.5547 16.4062 32.8438L23.9062 40.3438C25.1953 41.6328 27.1875 41.6328 28.4766 40.3438L43.4766 25.3438Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="tablet:hidden mobile:show">
                    <svg
                      width="50"
                      height="51"
                      viewBox="0 0 60 61"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 30.5C0 13.9766 13.3594 0.5 30 0.5C46.5234 0.5 60 13.9766 60 30.5C60 47.1406 46.5234 60.5 30 60.5C13.3594 60.5 0 47.1406 0 30.5ZM43.4766 25.3438C44.7656 24.0547 44.7656 22.0625 43.4766 20.7734C42.1875 19.4844 40.1953 19.4844 38.9062 20.7734L26.25 33.4297L20.9766 28.2734C19.6875 26.9844 17.6953 26.9844 16.4062 28.2734C15.1172 29.5625 15.1172 31.5547 16.4062 32.8438L23.9062 40.3438C25.1953 41.6328 27.1875 41.6328 28.4766 40.3438L43.4766 25.3438Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <div className="col-span-3">
                  <span className="text-white font-thin tablet:text-[18px] mobile:text-[16px]">
                    {modalData?.message || ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessModal;
