import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// Define the store state interface
interface VideoAnimationState {
  hasPlayedAnimation: boolean;
  markAnimationPlayed: () => void;
  resetAnimationState: () => void;
}

export const useLogoAnimationStore = create<VideoAnimationState>()(
  persist(
    (set) => ({
      hasPlayedAnimation: false,
      markAnimationPlayed: () => set({ hasPlayedAnimation: true }),
      resetAnimationState: () => set({ hasPlayedAnimation: false }),
    }),
    {
      name: 'video-animation-state',
      storage: {
        getItem: (name) => {
          const str = sessionStorage.getItem(name);
          return str ? JSON.parse(str) : null;
        },
        setItem: (name, value) => {
          sessionStorage.setItem(name, JSON.stringify(value));
        },
        removeItem: (name) => {
          sessionStorage.removeItem(name);
        },
      },
    }
  )
);