import { create } from "zustand";

const initialState = {
  modalData: {
    open: false,
    message: "",
  },
};
export const useSuccessModalStore = create((set) => ({
  initialState,
  setModalData: (data: []) =>
    set((state: object) => ({ ...state, modalData: data })),
}));
