import React from "react";
import { useMeQuery } from "src/generated/graphql";

const SidebarProfile = () => {
  const { data: meData } = useMeQuery();

  return (
    <>
      <div className="flex mt-10 ml-[35px] mobile:mt-16">
        <div className="flex ">
          <div className="flex flex-col justify-center ml-1.5 relative">
            <div className="flex flex-col justify-center mobile:absolute">
              <div className="flex flex-col capitalize justify-center text-[22px] text-white font-semibold mobile:text-[16px] mobile:w-28">
                {meData?.me?.firstName
                  ? `Hello ${meData?.me?.firstName}!`
                  : "Hello!"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10 ml-[35px] mr-[99px]">
        <svg
          className="mobile:w-[200px]"
          width="268"
          height="1"
          viewBox="0 0 268 1"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="0.5" x2="268" y2="0.5" stroke="#C6CED5" />
        </svg>
      </div>
    </>
  );
};

export default SidebarProfile;
